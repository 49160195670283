class window.Offside
  constructor: () ->
    @container = document.querySelector '.offside'
    unless @container
      console.warn 'Cannot initiate offside. No .offside container'
      return

    @content = @container.querySelector '.content'
    @toggle = @container.querySelector '.toggle'

    @toggle.addEventListener 'click', =>
      @container.classList.toggle 'open'

  close: () ->
    return unless @container.classList.contains 'open'
    @container.classList.remove 'open'

  open: () ->
    return if @container.classList.contains 'open'
    @container.classList.add 'open'
