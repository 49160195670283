// We use this attribute to associate a hidden input to a Dropzone preview element
// when the user removes a file from the Dropzone, the associated input field must be removed along with the Dropzone preview element
// without the shared attribute, we would not be able to make the link between preview element <-> hidden input
const associationAttribute = "dz-uuid";

const removeButtonClass = "dz-remove-button";

// When adding/removing MIME types here, the list of MIME types in auction.rb should also be updated
const validMIMETypes = ["image/gif", "image/jpeg", "image/png"];

/**
 * Adds the provided error to the provided file's preview element
 *
 * @param {File} file
 * @param {string} error
 */
import Dropzone from "dropzone"
import { i18nInstance } from './i18n/i18nSetup';
const addDropzoneError = (file, error) => {
    const previewElement = $(file.previewElement);

    // We must add this class otherwise the error message won't show on hover
    previewElement.addClass("dz-error");

    previewElement.find(".dz-error-mark").css("opacity", "1");
    previewElement.find(".dz-progress").css("opacity", "0");
    previewElement.find(".dz-error-message").find("span").text(error);
};

/**
 * @param {jQuery} element
 * @returns {object|undefined}
 */
const isDropzone = (element) => {
    return $(element)[0].dropzone;
};

/**
 * @param {File} file
 * @return {string}
 */
const getUuid = (file) => {
    return file.upload.uuid;
};

/**
 * Remove the hidden input field and Dropzone preview element with the given uuid
 *
 * @param {string} uuid The unique identifier from the Dropzone file's upload object
 */
const removeFileByUuid = (uuid) => {
    $(`input[${associationAttribute}='${uuid}']`).remove();
    $(`i.${removeButtonClass}[${associationAttribute}='${uuid}']`)
        .parent(".dz-preview")
        .remove();
};

/**
 * @param {File} file
 */
const removeFile = (file) => {
    removeFileByUuid(getUuid(file));
};

/**
 * Deletes all files from the given Dropzone
 *
 * @param {jQuery} element
 */
const clearDropzone = (element) => {
    const dropzone = jQueryToDropzone(element);

    dropzone.files.forEach(removeFile);
};

/**
 * @param {jQuery} element
 * @returns {Dropzone}
 */
const jQueryToDropzone = (element) => {
    return element.get(0).dropzone;
};

/**
 * @param {File} file
 * @param {object} response
 */
const addFile = (file, response) => {
    const dropzoneElement = $(file.previewElement).parent(".dropzone");

    if (!validFileType(file)) {
        addDropzoneError(file, window.I18n.t("dropzone.invalid_file_Type"));
        log(`Type ${file.type} not allowed from file ${file.name}`, "debug");
        return;
    }

    if (!response.hasOwnProperty("id")) {
        addDropzoneError(file, window.I18n.t("dropzone.upload_generic_error"));
        log(`Could not resolve blob ID for file ${file.name}`);
        return;
    }

    const inputName = getDropzoneInputName(dropzoneElement);

    if (!inputName) {
        addDropzoneError(file, window.I18n.t("dropzone.upload_generic_error"));
        log(
            "Could not resolve input name. Each Dropzone should have an input field with an appropriate 'name' attribute in its parent container"
        );
        return;
    }

    // Add an appropriately-named hidden input to the form with a
    // value of the blob's signed id so that the blob id will be
    // transmitted in the normal upload flow
    const hiddenInput = $("<input>");

    hiddenInput.attr("type", "hidden");
    hiddenInput.attr("value", response.id);
    hiddenInput.attr("name", inputName);
    hiddenInput.attr(associationAttribute, getUuid(file));

    dropzoneElement.closest("form").append(hiddenInput);
};

/**
 * @param {File} file
 * @returns {boolean}
 */
const validFileType = (file) => {
    return validMIMETypes.includes(file.type);
};

/**
 * @param {jQuery} element
 * @returns {jQuery}
 */
const getForm = (element) => {
    return $(element).closest("form");
};

/**
 * Enables or disables the submit button for the closest form for the provided element
 * If disabled, also adds an awaiting upload message to the Dropzone's info box (if one exists)
 *
 * @param {jQuery} element
 * @param {boolean} disabled
 */
const modifyFormSubmitButton = (element, disabled) => {
    const form = getForm($(element));

    if (disabled) {
        updateDropzoneInfo(element, window.I18n.t("dropzone.awaiting_upload"));
    }

    form.find("input[type='submit']").attr("disabled", disabled);
};

/**
 * Sets the provided element's Dropzone info box's HTML to the provided message
 *
 * @param {jQuery} element
 * @param {string|undefined} message
 */
const updateDropzoneInfo = (element, message = undefined) => {
    const alert = $(getForm(element).find("div.flash.callout.dropzone-info"));

    if (alert) {
        if (typeof message === "string") {
            alert.html(message);
        } else if (typeof message === "undefined") {
            alert.html(alert.attr("default"));
        }
    }
};

/**
 * Each Dropzone should have a hidden and disabled input field in its container
 * The 'name' attribute of this input field will be the name used for the generated hidden input fields for each file will have
 * For example 'auction[images][]'
 *
 * @param {jQuery|object} dropzone jQuery object or Dropzone object with valid 'previewsContainer' property
 * @returns {string|undefined}
 */
const getDropzoneInputName = (dropzone) => {
    if (dropzone instanceof jQuery) {
        return dropzone.parent().find("input").attr("name");
    }

    if (dropzone.hasOwnProperty("previewsContainer")) {
        return $(dropzone.previewsContainer).parent().find("input").attr("name");
    }
};

/**
 * @param dropzoneElement The jQuery element that is being initialized as a Dropzone
 * @returns {object} The Dropzone options
 */
const optionsMap = (dropzoneElement, csrfToken) => {
    let defaultOptions = {
        url: dropzoneElement.data('url') || "/upload",
        sending: function(file, xhr, formData) {
            xhr.setRequestHeader('X-CSRF-Token', csrfToken);
        }  
    };
    if (dropzoneElement.is("#chat-dropzone")) {
        return {
            ...defaultOptions,
            clickable: "button.trumbowyg-upload-button",
        };
    }
    return defaultOptions;
};

const initializeDropzone = (csrfToken) => {
    $(".dropzone").each(function () {
        const element = $(this);
        if (!isDropzone(element)) {
            const options = optionsMap(element, csrfToken);

            if (!options.url) {
                console.error('No URL provided for element: ', element);
            }

            element.dropzone(options);
        }
    });
};

Dropzone.autoDiscover = false;

// Set default messages based on screen width
const defaultMessage = (screen.width <= 400) ? 
    i18nInstance.t("dropzone.default_mobile") : 
    i18nInstance.t("dropzone.default_message");

// Configure Dropzone default options
Dropzone.prototype.defaultOptions = {
    ...Dropzone.prototype.defaultOptions, // Spread to preserve any other default options
    dictDefaultMessage: defaultMessage,
    dictInvalidFileType: i18nInstance.t("dropzone.invalid_file_Type"),
    dictRemoveFile: i18nInstance.t("dropzone.remove_file"),
    url: "/upload",
    params: {
        authenticity_token: window._token,
    },
    success: addFile,
    error: (file, error) => {
        console.log("LOL", file);
        addDropzoneError(file, i18nInstance.t("dropzone.upload_generic_error"));
        // log(error);
    },
    init: function () {
        this.on("processing", function () {
            modifyFormSubmitButton($(this.previewsContainer), true);
        });

        this.on("processing", function (file) {
            const removeButton = $('<i class="fas fa-times-circle"></i>');
            removeButton.addClass(removeButtonClass);
            removeButton.attr(associationAttribute, getUuid(file));
            removeButton.on("click", () => {
                this.removeFile(file);
            });
            $(file.previewElement).append(removeButton);
        });

        this.on("complete", function (file) {
            if (this.getUploadingFiles().length === 0) {
                modifyFormSubmitButton($(file.previewElement), false);
                updateDropzoneInfo($(file.previewElement), "");
            }
        });

        this.on("removedfile", function (file) {
            removeFile(file);
            const inputName = getDropzoneInputName(this);
            if ($(`input[name='${inputName}']:enabled`).length === 0) {
                updateDropzoneInfo(this.previewsContainer);
            }
        });
    }
};

export const DropzoneUtils = {
    initializeDropzone
};
