# Form errors
#
# Attempt to display the correct tab panel, which contains the form errors.
#
class window.FormErrors
  constructor: (form) ->
    window.wait 400, ->
      errors = [].slice.call form.querySelectorAll(".input.error")

      # Check if we have any invisble errors. In that case, attempt to navigate to them.
      errors.forEach (error) =>
        $error = $(error)

        unless $error.is(":visible")
          console.log "Invisible error", error
          id = $error.closest(".tabs-panel").attr("id")
          console.log "Closest panel", id
          link = $(".tabs a[href='##{id}']")
          console.log "tabs link", link[0]
          if link
            link.click()
            return
