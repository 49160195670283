class window.GoogleMap
  constructor: (container) ->
    lat = parseFloat(container.getAttribute('data-lat') || '55.4038')
    lng = parseFloat(container.getAttribute('data-lng') || '10.4042')

    location =
      lat: lat,
      lng: lng

    @map = new google.maps.Map container,
      zoom: 13,
      center: location,
      disableDefaultUI: true
      zoomControl: true

    @marker = new google.maps.Marker
      position: location,
      map: @map


  autocomplete: (input, country = 'dk') ->
    autocomplete = new google.maps.places.Autocomplete input
    autocomplete.bindTo 'bounds', @map
    autocomplete.setComponentRestrictions country: country

    @map.controls[google.maps.ControlPosition.TOP_LEFT].push input

    autocomplete.addListener 'place_changed', =>
      place = autocomplete.getPlace()
      return unless place.geometry

      if place.geometry.viewport
        @map.fitBounds place.geometry.viewport

      else
        @map.setCenter place.geometry.location
        @map.setZoom 13

      @marker.setPlace
        placeId: place.place_id,
        location: place.geometry.location
