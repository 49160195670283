class window.Chat
  constructor: (@auction_id) ->
    @container = document.querySelector '#chat'
    @messageContainer = @container.querySelector '.messages ul'
    @spinner = @container.querySelector '.load-more'
    @url = "/chat_messages/#{@auction_id}"
    @loadMessages()
    @listenForMessage()
    @observeForm()

  # Load messages from the server based on the timestamp of the earliest
  # timestamp of the currently loaded message.
  loadMessages: ->
    earliestMessage = @messageContainer.querySelector('li:first-child .by')
    timestamp = earliestMessage.getAttribute('data-date') if earliestMessage
    timestamp ?= new Date().toUTCString()
    $.get "#{@url}?before=#{timestamp}", (html) =>
      if html == ''
        @spinner.classList.add 'hide'
      else
        @messageContainer.insertAdjacentHTML 'afterbegin', html
        @scrollIntoView html
        @infiniteScrolling()

  # Scroll specific message into view
  scrollIntoView: (html) ->
    elements = $(html)
    id = elements.last()[0].getAttribute "id"
    @messageContainer.querySelector("##{id}").scrollIntoView true

  highlightLast: ->
    li = @messageContainer.querySelector("li:last-child")
    li.classList.add 'highlight'
    window.wait 500, => li.classList.remove 'highlight'

  # Listen for Pusher event, and inject the new message
  listenForMessage: ->
    pusher = new PusherInstance()
    stream = "private-auctions-#{@auction_id}-chat-messages"
    channel = pusher.instance.subscribe stream
    channel.bind "new-message", (id) =>
      $.get "#{@url}/#{id}", (html) =>
        @messageContainer.insertAdjacentHTML 'beforeend', html
        @scrollIntoView html

  # When the spinner comes into view load more chat messages
  infiniteScrolling: ->
    return unless @spinner
    callback = (entries) =>
      entries.forEach (entry) => @loadMessages() if entry.isIntersecting
    new IntersectionObserver(callback).observe(@spinner)

  # When the form is submitted clear the form
  observeForm: ->
    form = @container.querySelector 'form'
    textarea = @container.querySelector 'form textarea'
    button = form.querySelector "input[type='submit']"

    # Prevent change event from propagating and triggering tab change.
    textarea.addEventListener 'change', (e) ->
      e.preventDefault()
      e.stopPropagation()
      e.stopImmediatePropagation()

    form.addEventListener 'ajax:complete', (e) =>
      e.preventDefault()
      e.stopPropagation()
      e.stopImmediatePropagation()
      $(textarea).trumbowyg 'empty'
      @clearDropzone()
      button.removeAttribute 'disabled'

  clearDropzone: ->
    dropzone = $(@container).find ".dropzone"

    if dropzone
      clearDropzone dropzone
