class window.Countdown
  @startAll: ->
    collection = document.querySelectorAll '.countdown'
    [].slice.call(collection).forEach (el) ->
      new Countdown(el).start()

  constructor: (@element) ->
    @date = new Date(@element.getAttribute 'data-date').getTime()

  start: ->
    window.tock = => @tick() # expose to setInterval scope
    @interval = setInterval window.tock, 60000
    window.tock()

  stop: -> clearInterval @interval

  tick: ->
    now = new Date().getTime()
    delta = @date - now
    days = Math.floor(delta / (1000 * 60 * 60 * 24));
    hours = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
    seconds = Math.floor((delta % (1000 * 60)) / 1000);
    @element.innerHTML = "#{days}d #{hours}h #{minutes}m"
