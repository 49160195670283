document.addEventListener("DOMContentLoaded", () => {
    console.log("DOM content loaded");
    const registerClickButtons = document.querySelectorAll(".register-click-button");
    
    registerClickButtons.forEach((button) => {
      button.addEventListener("click", () => {
        const technicianId = button.getAttribute("data-technician-id");
        console.log("Button clicked for technician:", technicianId)
        fetch(`/repairman_clicks/${technicianId}/register_click`, {
          method: "POST",
          headers: { "X-CSRF-Token": Rails.csrfToken() },
        })
          .then((response) => response.json())
      });
    });
  });
  