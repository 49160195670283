#tabsDeepLink = ->
#  window.wait 300, ->
#    hash = new URL(window.location.href).hash
#    anchor = document.querySelector ".tabs a[href='" + hash + "']"
#
#    if anchor
#      anchor.scrollIntoView false
#      $(anchor).click()
#
#    [].slice.call(document.querySelectorAll(".tabs-title a")).forEach (a) ->
#      a.addEventListener "click", (e) ->
#        window.location.hash = a.getAttribute "href"
#
#try
#  tabsDeepLink()
#catch
#  document.addEventListener "turbolinks:load", -> tabsDeepLink()
#  document.addEventListener "DOMContentLoaded", -> tabsDeepLink()

tabsDeepLink = ->
  setTimeout(() ->
    hash = new URL(window.location.href).hash
    anchor = document.querySelector ".tabs a[href='" + hash + "']"

    if anchor
      anchor.scrollIntoView false
      $(anchor).click()

    [].slice.call(document.querySelectorAll(".tabs-title a")).forEach (a) ->
      a.addEventListener "click", (e) ->
        window.location.hash = a.getAttribute "href"
  , 300)

try
  tabsDeepLink()
catch
  document.addEventListener "turbolinks:load", -> tabsDeepLink()
  document.addEventListener "DOMContentLoaded", -> tabsDeepLink()
