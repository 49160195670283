/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Modules import
import jQuery from "jquery";
import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import Turbolinks from "turbolinks";
import Foundation from "foundation-sites";
import Isotope from "isotope-layout";
import moment from "moment";
import Pikaday from "pikaday";
import lightbox from "lightbox2";
import Trumbowyg from "trumbowyg";
import Cookies from "js-cookie";
import { i18nInstance } from './i18n/i18nSetup';

import './_form.js';
import './close_button.js.coffee';
import './google.map.js.coffee';
import './countdown.js.coffee';
import './offside.js.coffee';
import './collapse_tabs.js.coffee';
import './tabs.js.coffee'
import './chat.js.coffee'
import './pusher_instance.js.coffee.erb'
import './phone_button'
import './register_click'
import './toggle.js'

// Global availability
window.jQuery = jQuery;
window.$ = jQuery;
window.Rails = Rails;
window.ActiveStorage = ActiveStorage
window.Foundation = Foundation;
window.FormErrors = FormErrors;
window.CookieCloseButton = CookieCloseButton;
window.Cookies = Cookies;
window.GoogleMap = GoogleMap;
window.Countdown = Countdown;
window.Isotope = Isotope;
window.Offside = Offside;
window.CollapseTabs = CollapseTabs;
window.Chat = Chat
window.PusherInstance = PusherInstance
window.Pikaday = Pikaday;
window.Trumbowyg = Trumbowyg;

import { DropzoneUtils } from './dropzone.js';

window.wait = function(ms, callback) {
  setTimeout(callback, ms);
};

$(document).ready(function() {
  i18nInstance.locale = window.app_locale; // "da" or "en"

  $('#locale-dk, #locale-en').on('click', function(e) {
    e.preventDefault();

    let newLocale = $(this).data('locale');
    i18nInstance.locale = newLocale;
    let translation = i18nInstance.t('activerecord.attributes.auction.images');
    if (translation) {
      console.log(translation);
    } else {
      console.warn('Translation not found');
    }
    window.location = `${window.location.pathname}?locale=${newLocale}`;

    $.ajax({
      url: '/change_locale',
      method: 'POST',
      data: { locale: newLocale },
      success: function() {
        try {
          window.app_locale = newLocale;
          Cookies.set('app_locale', newLocale);
          window.location.href = window.location.href.split('?')[0] + '?nocache=' + new Date().getTime();
        } catch (error) {
          console.error('Error:', error);
        }
      }
    });
  })
});

lightbox.option({
  'wrapAround': true
});

/**
 * @param {string} message The message that should be logged
 * @param {('error'|'info'|'debug'|'warn')} type The severity of the message
 */
window.log = (message, type = "error") => {
  $.ajax({
      url: `${window.location.origin}/logs`,
      type: 'POST',
      data: {message: message, type: type},
      dataType: 'json',
      headers: {
          'X-CSRF-Token': window._token,
      },
  });
};

/**
 * Restricts input for each element in the set of matched elements to the given inputFilter.
 * see https://stackoverflow.com/questions/995183/how-to-allow-only-numeric-0-9-in-html-inputbox-using-jquery
 */
$.fn.inputFilter = function(inputFilter) {
  return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
      if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      }
  });
};

window.loadCsrfToken = function () {
  $.getJSON('/utils/csrf').then(function(json) {
    $('<meta>')
      .attr('name', "csrf-param")
      .attr('content', json.param)
      .appendTo('head');

    $('<meta>')
      .attr('name', 'csrf-token')
      .attr('content', json.token)
      .appendTo('head');

    $("input[name='authenticity_token']").each(function() {
      $(this).val(json.token);
    });
  });
  let csrfTokenElement = document.querySelector("meta[name='csrf-token']");
    if (csrfTokenElement) {
        let csrfToken = csrfTokenElement.getAttribute("content");
        console.log("TOKEN", csrfToken);
        DropzoneUtils.initializeDropzone();
    } else {
        console.error("CSRF token meta tag not found");
    }
};

/**
 * @returns {number}
 */
window.getHeaderHeight = () => {
    return $('#responsive-menu').height();
};

window.scrollToErrorField = () => {
    const errors = $('.error');

    // If there are no errors, do nothing
    if (errors.length < 1) {
        return;
    }

    const firstError = $(errors[0]);
    const container = firstError.closest('.input');
    const offset = container.offset().top;

    window.scroll({
        top: offset - getHeaderHeight(),
        left: 0,
        behavior: "smooth",
    });
};

function initializeApp() {
  $(document).foundation();
  lightbox.init();
  scrollToErrorField();
  $('input[type="tel"]').inputFilter(function(value) {
    return /^\d*$/.test(value);
  });
}

function loadAndInitializeDropzone() {
  $.getJSON('/utils/csrf').then(function(json) {
    $('<meta>')
      .attr('name', "csrf-param")
      .attr('content', json.param)
      .appendTo('head');

    $('<meta>')
      .attr('name', 'csrf-token')
      .attr('content', json.token)
      .appendTo('head');

    $("input[name='authenticity_token']").each(function() {
      $(this).val(json.token);
    });

    let csrfTokenElement = document.querySelector("meta[name='csrf-token']");
    if (csrfTokenElement) {
        let csrfToken = csrfTokenElement.getAttribute("content");
        DropzoneUtils.initializeDropzone(csrfToken);
    } else {
        console.error("CSRF token meta tag not found");
    }
  });
}

// On initial page load
$(document).ready(function() {
  initializeApp();
  loadAndInitializeDropzone();
});

// On Turbolinks page load
$(document).on("turbolinks:load", function() {
  initializeApp();
  loadAndInitializeDropzone();
});
