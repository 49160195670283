
# Re-usable function for tabs change

class window.CollapseTabs
  constructor: ->
    @auctionsTabs = document.querySelector('.tabs.clean')
    collapseChevron = document.querySelector("#auctions-tabs-chevron")
    _this = this
    collapseChevron.addEventListener "click", (e) -> _this.handleClick(e)
    collapseList = document.querySelectorAll('.tabs-title')
    collapseList.forEach (list) -> 
      list.addEventListener "click", () ->
        _this.collapse()

  handleClick: (e) ->
    iconArrow = document.querySelector('#auctions-tabs-chevron-icon')
    e.preventDefault() 
    if iconArrow.classList.contains('fa-chevron-down')
      @collapse()
    else 
      @expand()

  collapse: ->
    iconArrow = document.querySelector('#auctions-tabs-chevron-icon')
    iconArrow.classList.remove('fa-chevron-down')
    iconArrow.classList.add('fa-chevron-up')
    @auctionsTabs.classList.add('collapse')          

  expand: ->
    iconArrow = document.querySelector('#auctions-tabs-chevron-icon')
    iconArrow.classList.remove('fa-chevron-up')
    iconArrow.classList.add('fa-chevron-down')
    @auctionsTabs.classList.remove('collapse')