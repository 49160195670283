# re usable toggle component
window.toggleContent = (toggleId) ->

  content = document.getElementById "#{toggleId}-content"
  icon = document.getElementById "toggleIcon#{toggleId}"

  if content.style.display == 'block'
    content.style.display = 'none'
    icon.classList.remove 'fa-chevron-up'
    icon.classList.add 'fa-chevron-down'
  else
    content.style.display = 'block'
    icon.classList.remove 'fa-chevron-down'
    icon.classList.add 'fa-chevron-up'

