class window.CookieCloseButton
  constructor: (button) ->
    name = button.getAttribute "id"

    # Add cookie if closed
    button.addEventListener "click", =>
      Cookies.set name, 1

    # Close if cookie exists
    $(button).parent("*[data-closable]").hide() if Cookies.get name
